import { css } from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

export const squareCardBorderRadius = css`
  border-radius: ${sizeUnits[4]};
  overflow: clip;
`;

export const roundedCardBorderRadius = css`
  border-radius: ${sizeUnits[8]};
  overflow: clip;
`;
