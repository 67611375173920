import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';

type Props = PropsWithChildren<{
  className?: string;
}>;

export const Label = ({ children, className }: Props) => {
  return (
    <LabelWrapper className={className}>
      <Typography variant="label">{children}</Typography>
    </LabelWrapper>
  );
};

const LabelWrapper = styled.span`
  background-color: ${colors.red.tv4};
  display: inline-flex;
  align-items: center;
  border-radius: ${sizeUnits[4]};
  color: ${colors.white[100]};
  justify-content: center;
  padding: ${sizeUnits[4]} ${sizeUnits[8]};
`;
