import styled, { keyframes } from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { squareCardBorderRadius } from 'styles/mixins/borderRadius';

const LoaderAnimation = keyframes`
  from { transform: scale(1)}
  50% {
    transform: scale(.5);
  }
  to {
    background: #DA2C2D;
    transform: scale(1)
  }
`;

export const Loader = styled.div`
  width: ${sizeUnits[32]};
  height: ${sizeUnits[32]};
  background: ${colors.red.tv4};
  border-radius: 50%;
  animation: ${LoaderAnimation} infinite 1s linear;
`;

const shiny = keyframes`
  from {  background-position: 0 0}
  to {background-position: 100% 100%}
`;

export const Placeholder = styled.div<{ $colors?: [string, string] }>`
  ${squareCardBorderRadius}
  background: ${(props) =>
    props.$colors
      ? `linear-gradient(135deg, ${props.$colors[0]} 0%, ${props.$colors[1]} 30%, ${props.$colors[0]} 60%)`
      : 'linear-gradient(135deg, #ffffff 0%, #e6e6e6 30%, #ffffff 60%)'};
  background-size: 1000%;
  animation-name: ${shiny};
  animation-duration: 1.2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-direction: reverse;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
`;
