import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { feedLayout, sideBarLayout } from 'styles/mixins/feed';
import { theme } from 'styles/theme';

export const SidebarContainer = styled.aside`
  ${sideBarLayout}
`;

export const FeedContentContainer = styled.div<{ $isPaddingTop?: boolean }>`
  ${feedLayout}
  grid-row-gap: ${sizeUnits[8]};

  &:empty {
    display: none;
  }

  .hidden {
    display: none;
  }

  ${theme.mq.desktop} {
    grid-row-gap: ${sizeUnits[16]};
  }

  ${({ $isPaddingTop }) =>
    $isPaddingTop &&
    `
    padding-top: ${sizeUnits[8]};
    ${theme.mq.tablet} {
      padding-top: ${sizeUnits[16]};
    }
  `}
`;

export const LoadMoreButtonContainer = styled.div`
  padding-bottom: ${sizeUnits[16]};
  margin-top: -${sizeUnits[8]};
`;
