import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Container } from 'components/container';
import { Label } from 'components/labels';
import { FeedContentContainer } from 'components/newsfeed/style';
import { theme } from 'styles/theme';

import { Placeholder } from './style';

const RelatedPlaceholder = () => {
  return (
    <RelatedPlaceholderContainer>
      <RelatedImagePlaceholder />
      <RelatedTextPlaceholder>
        <RelatedParagraphPlaceholder $height="20px" />
        <RelatedParagraphPlaceholder $height="14px" />
      </RelatedTextPlaceholder>
    </RelatedPlaceholderContainer>
  );
};

const RelatedImagePlaceholder = styled(Placeholder)`
  width: 100%;
  height: 85px;
  max-width: 150px;
`;

const RelatedParagraphPlaceholder = styled(Placeholder)<{ $height: string }>`
  width: 80%;
  height: ${({ $height }) => $height};

  ${theme.mq.tablet} {
    width: 60%;
  }
`;

const RelatedPlaceholderContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${sizeUnits[8]};
  gap: ${sizeUnits[16]};
  padding-left: ${sizeUnits[8]};
  padding-right: ${sizeUnits[8]};
`;

const RelatedTextPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${sizeUnits[8]};
`;

const ArticlePlaceholder = () => {
  return (
    <>
      <ArticleImagePlaceholder />
      <ArticleTextPlaceholder>
        <ArticleTitlePlaceholder />
        <ArticleParagraphPlaceholder $width="50%" $height="15px" />
        <ArticleParagraphPlaceholder $width="50%" $height="10px" />
      </ArticleTextPlaceholder>
    </>
  );
};

const ArticleImagePlaceholder = styled(Placeholder)`
  width: 100%;
  aspect-ratio: 16 / 9;
`;

const ArticleTitlePlaceholder = styled(Placeholder)`
  height: 20px;
  width: 75%;
  ${theme.mq.desktop} {
    width: 100%;
  }
`;

const ArticleParagraphPlaceholder = styled(Placeholder)<{ $width: string; $height: string }>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

const ArticleTextPlaceholder = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  margin-top: ${sizeUnits[8]};
  padding-left: ${sizeUnits[8]};
  padding-right: ${sizeUnits[8]};
  margin-bottom: ${sizeUnits[12]};
  gap: ${sizeUnits[12]};

  ${theme.mq.tablet} {
    width: 40%;
  }
`;

export const BreakingNewsWidgetLoading = () => {
  return (
    <BreakingNewsWidgetPlaceholder>
      <Label>Senaste nytt</Label>
      <BreakingNewsPlaceholder />
      <BreakingNewsPlaceholder />
      <BreakingNewsPlaceholder />
    </BreakingNewsWidgetPlaceholder>
  );
};

const BreakingNewsPlaceholder = () => {
  const placeholderColors = [colors.gray.fade, colors.gray.dark] as [string, string];

  return (
    <BreakingNewsTextContainer>
      <BreakingNewsParagraphPlaceholder $width="40%" $height="8px" $colors={placeholderColors} />
      <BreakingNewsParagraphPlaceholder $width="80%" $height="20px" $colors={placeholderColors} />
      <BreakingNewsParagraphPlaceholder $width="70%" $height="30px" $colors={placeholderColors} />
    </BreakingNewsTextContainer>
  );
};

const BreakingNewsParagraphPlaceholder = styled(Placeholder)<{ $width: string; $height: string }>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

const BreakingNewsTextContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-top: ${sizeUnits[32]};
  gap: ${sizeUnits[12]};
`;

const BreakingNewsWidgetPlaceholder = styled(Placeholder)`
  width: 100%;
  height: 400px;
  padding: ${sizeUnits[16]};
`;

export const SportResultsLoading = () => {
  const placeholderColors = [colors.gray.fade, colors.gray.dark] as [string, string];

  return (
    <SportResultsWrapper>
      <div style={{ display: 'flex', flexDirection: 'column', gap: sizeUnits[8] }}>
        <SportResultsRowItem $width="40%" $height="24px" $colors={placeholderColors} />
        <SportResultsPlaceholder />
        <SportResultsPlaceholder />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: sizeUnits[8] }}>
        <SportResultsRowItem $width="40%" $height="24px" $colors={placeholderColors} />
        <SportResultsPlaceholder />
        <SportResultsPlaceholder />
      </div>
    </SportResultsWrapper>
  );
};

const SportResultsPlaceholder = () => {
  const placeholderColors = [colors.gray.fade, colors.gray.dark] as [string, string];

  return (
    <SportResultsTextContainer>
      <SportResultsRowItem $width="100%" $height="68px" $colors={placeholderColors} />
    </SportResultsTextContainer>
  );
};

const SportResultsWrapper = styled(Placeholder)`
  width: 100%;
  height: 400px;
  padding: ${sizeUnits[16]};
  display: flex;
  flex-direction: column;
  gap: ${sizeUnits[24]};
  margin-top: 3px;
`;

const SportResultsTextContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: ${sizeUnits[12]};
`;

const SportResultsRowItem = styled(Placeholder)<{ $width: string; $height: string }>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

export const Loading = () => {
  return (
    <Container id="main-content">
      <LoadingFeedContainer $isPaddingTop>
        <LoadingPriority />
      </LoadingFeedContainer>
    </Container>
  );
};

const LoadingFeedContainer = styled(FeedContentContainer)`
  padding-bottom: 60px;
`;

/** @internal */
export const LoadingPriority = () => {
  const placeHolders = [];

  for (let i = 0; i < 5; i++) {
    placeHolders.push(
      <article key={i}>
        <ArticlePlaceholder />
        <RelatedPlaceholder />
        <RelatedPlaceholder />
      </article>
    );
  }

  return placeHolders;
};
