import { css } from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { MaxWidthLayout } from 'components/common';
import { theme } from 'styles/theme';

export const sideBarLayout = css`
  max-width: 450px;
  position: absolute;
  top: ${sizeUnits[16]};
  width: 31%;
  left: 66%;
  display: block;
  height: 100%;

  & > *:not(:first-child) {
    margin-top: ${sizeUnits[16]};
  }
`;

export const feedLayout = css`
  position: relative;
  justify-content: start;
  display: grid;
  grid-template-columns: 100%;

  ${theme.mq.desktop} {
    grid-template-columns: 66%;
  }

  ${MaxWidthLayout()}
`;
