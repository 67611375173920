import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

export const Container = styled.main`
  background: ${colors.gray.dark};
  position: relative;

  ${({ theme }) => theme.mq.tablet} {
    padding-bottom: ${sizeUnits[24]};
  }
`;
